import React, { Component } from 'react';
import UnAuthorizedComponent from './Components/unAuthorizedComponent';
import AuthorizedComponent from './Components/authorizedComponent';
import { authServiceInstance, applicationTitle, appTheme, appTimerExecs, objVoiceRecorder, whenAppInit } from './General/globals';
import { Backdrop, CircularProgress, ThemeProvider, createMuiTheme } from '@material-ui/core';
import { HashRouter as Router, Switch as RouteSwitch, Route } from 'react-router-dom';
// import store from './AppRedux/store';
import { authLogout, authLogin } from './AppOwnState';
import { ExecuteLayoutEventMethods } from './General/commonFunctions';
import './Styles/animate.min.css';
import './Styles/basic.css';
import './Styles/responsive.css';
import { GPSLocation, DownloadFileFromState, } from './General/globalFunctions';
import AppDialog from './Components/Parts/AppControls/appDialog';
import AppSnackBar from './Components/Parts/AppControls/appSnackBar';
import AppMenu from './Components/Parts/AppControls/appMenu';
import appNotification from './Components/Parts/AppControls/appNotification';
import { ownStore } from './AppOwnState/ownState';
import AppBackDrop from './Components/Parts/AppControls/appBackDrop';

class App extends Component {
  constructor() {
    super();

    this.state = {
      isPageLoading: true,
      isAuthenticated: false,
      // isAppBusy: false,
      pageInfo: null,
      // popupInfo: null,
      // snackInfo: { open: false },
      // dialogInfo: { open: false },
      // menuInfo: { open: false }
    };

    this.pageTimerExecs = null;
    this.popupTimerExecs = null;

    GPSLocation();

    this.idleTimer = 0;
    this.idleReset(); //*To track if the user is idle or not
    // this.saveGlobalAppState = this.saveGlobalAppState.bind(this);
    //test

    this.unsubscribe = ownStore.subscribe((storeInfo) => {
      let storeDset = storeInfo.dset;
      if (storeDset === "login") {
        let loginDS = ownStore.getState("login");
        if (loginDS?.isAuthenticated !== this.state.isAuthenticated) {
          this.setState({
            isAuthenticated: loginDS?.isAuthenticated
          });
        }
      }

      // if (storeDset === "isAppBusy") {
      //   let isAppBusy = ownStore.getState("isAppBusy");
      //   this.setState({
      //     isAppBusy: isAppBusy
      //   });
      // }

      if (storeDset === "pageinfo") this.pageStateChange(ownStore.getState("pageinfo"));
      // if (storeDset === "popupinfo") this.popupStateChange(ownStore.getState("popupinfo"));
      // if (storeDset === "snackinfo") this.snackStateChange(ownStore.getState("snackinfo"));
      // if (storeDset === "dialoginfo") this.dialogStateChange(ownStore.getState("dialoginfo"));
      // if (storeDset === "menuinfo") this.menuStateChange(ownStore.getState("menuinfo"));
      if (storeDset === "notifyinfo") appNotification(ownStore.getState("notifyinfo"));
      if (storeDset === "mediainfo") {
        let mediaInfo = ownStore.getState("mediainfo");
        mediaInfo && objVoiceRecorder.doAction(mediaInfo);
      }
      if (storeDset === "downloadinfo") {
        let downloadInfo = ownStore.getState("downloadinfo");
        if (downloadInfo?.open) DownloadFileFromState(downloadInfo); //*Download base64 as file if any 
      }
    });

    this.applyAppOptions();

  }

  idleReset() { //*To reset idleTimer on mousemove; To be used later; Need to add it along with timer functions
    document.addEventListener("mousemove", () => this.idleTimer = 0);
  }

  async applyAppOptions() {
    if (appTheme) {
      this.theme = createMuiTheme(appTheme);
      if (appTheme?.header)
        document.querySelector("meta[name=theme-color]").setAttribute("content", appTheme?.header);
    }
  }

  async componentDidMount() {
    ExecuteLayoutEventMethods(whenAppInit); //*AppInit events from Initjson

    let authOK = await authServiceInstance.verifyLogin();

    // this.rootStateHandler = this.rootStateHandler.bind(this);
    ownStore.dispatch(authOK ? authLogin() : authLogout()); //Redux

    this.setState({
      isPageLoading: false
    });
    this.runAppTimer();
  }

  componentWillUnmount() {
    this.unsubscribe();
    clearInterval(this.timeInterval);
  }

  pageStateChange(dsPageInfo) {
    // if (dsPageInfo) {
    // let reduxPageInfoState = JSON.stringify(dsPageInfo),
    //   localPageInfoState = JSON.stringify(this.state.pageInfo);
    // if (reduxPageInfoState !== localPageInfoState) {

      let layoutInfo = dsPageInfo;
      let pInfo = layoutInfo;

      document.title = `${applicationTitle} ${layoutInfo?.title ? " - " + layoutInfo?.title : ""}`;
      // await ExecuteLayoutEventMethods(pInfo?.wheninit, pInfo); 
      this.setState({
        pageInfo: pInfo
      },
        () => {
          ExecuteLayoutEventMethods(pInfo?.whenload, pInfo); //Execute onload functions of Loaded Page
          this.pageTimerExecs = pInfo?.whentimer;
        });
    // }
    // }
  }

  // popupStateChange(dsPopupInfo) { //TODO 05Dec2021: Need to isolate the state of Popup in future to prevent rerendering
  //   if (dsPopupInfo) {
  //     // let reduxPopupInfoState = JSON.stringify(dsPopupInfo),
  //     //   localPopupInfoState = JSON.stringify(this.state.popupInfo);
  //     // if (reduxPopupInfoState !== localPopupInfoState) {

  //       // let popupLayoutInfo = pageDs?.popupinfo;
  //       let plInfo = dsPopupInfo;

  //       let needPopupEventExecution = [];
  //       // let currentURLHash = window.location.hash;
  //       // window.history.pushState({}, '')
  //       Object.keys(plInfo).forEach((pkey) => {
  //         let reduxPopupState = JSON.stringify(plInfo[pkey]),
  //           localPopupState = this.state.popupInfo && JSON.stringify(this.state.popupInfo[pkey]);
  //         if (reduxPopupState !== localPopupState) {

  //           // currentURLHash = currentURLHash + "/" + pkey;

  //           needPopupEventExecution.push(pkey); //* Pushing from the existing dataset is needed as there may be changes in the existing popup too... like layout change.
  //         }
  //       });
  //       // window.location.hash = currentURLHash;

  //       // for(const pkey of needPopupEventExecution){
  //       //    await ExecuteLayoutEventMethods(plInfo[pkey]?.wheninit, plInfo[pkey]); //Execute init functions of Loaded Popup
  //       // }

  //       this.setState({
  //         popupInfo: plInfo
  //       },
  //         () => {
  //           // window.history.pushState({}, '')
  //           needPopupEventExecution.forEach((pkey) => {
  //             ExecuteLayoutEventMethods(plInfo[pkey]?.whenload, plInfo[pkey]); //Execute onload functions of Loaded Popup
  //             this.popupTimerExecs = plInfo[pkey]?.whentimer;
  //           });
  //         });
  //     }
  //   // }
  // }

  // snackStateChange(dsSnackInfo) {
  //   // let reduxSnackInfoState = JSON.stringify(dsSnackInfo),
  //   //   localSnackInfoState = JSON.stringify(this.state.snackInfo);
  //   // if (reduxSnackInfoState !== localSnackInfoState) {

  //     // this.setState({
  //     //   snackInfo: dsSnackInfo
  //     // }); 
  //   // }
  // }

  // dialogStateChange(dsDialogInfo) {
  //   if (dsDialogInfo) {
  //     // let reduxDialogInfoState = JSON.stringify(dsDialogInfo),
  //     //   localDialogInfoState = JSON.stringify(this.state.dialogInfo);
  //     // if (reduxDialogInfoState !== localDialogInfoState) {

  //     this.setState({
  //       dialogInfo: dsDialogInfo
  //     });
  //     // }
  //   }
  // }

  // menuStateChange(dsMenuInfo) {
  //   // let reduxMenuInfoState = JSON.stringify(dsMenuInfo),
  //   //   localMenuInfoState = JSON.stringify(this.state.menuInfo);
  //   // if (reduxMenuInfoState !== localMenuInfoState) {

  //     this.setState({
  //       menuInfo: dsMenuInfo
  //     });
  //   // }
  // }



  runAppTimer() {
    this.timerSeconds = 0;
    this.timeInterval = setInterval(() => {


      if (this.state.isAuthenticated) {
        this.executeTimerFunctions(appTimerExecs); //*Execute timer functions of App; Need to pass it from UserDetails

        this.executeTimerFunctions(this.pageTimerExecs); //*Execute timer functions of Loaded Page
        this.executeTimerFunctions(this.popupTimerExecs); //*Execute timer functions of Loaded Popup
        this.timerSeconds++;
        this.idleTimer++;
      }

      if (this.idleTimer >= 86400) this.idleTimer = 0;  //*Resetting idleTimer every 24 hours just to prevent overflow exception
      if (this.timerSeconds >= 86400) this.timerSeconds = 0;  //*Resetting TimerSeconds every 24 hours just to prevent overflow exception

    }, 1000);


  }

  executeTimerFunctions(timerFunctions) {
    if (timerFunctions) {

      for (let tfn of timerFunctions) {
        if (this.timerSeconds % tfn?.interval === 0) {
          ExecuteLayoutEventMethods(tfn.tasks);
        }
      }
    }
  };




  // rootStateHandler(stateToMerge) {

  //   if (stateToMerge) {
  //     this.setState(stateToMerge, function () {

  //       // let jsonState = JSON.stringify(this.state); 
  //       // localStorage.setItem("as", jsonState); 
  //     });

  //   }
  // }


  _getComponentToStart(routerProps) {
    return (this.state.isAuthenticated ?
      <AuthorizedComponent pageInfo={this.state.pageInfo}   {...routerProps} />
      :
      <UnAuthorizedComponent {...routerProps} />
    );
  }



  render() {
    console.log("RENDER APP");
    if (this.state.isPageLoading) return (
      <Backdrop open={this.state.isPageLoading} >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
    // console.log(this.state.isAppBusy )
    // if ()
    return (
      <ThemeProvider theme={this.theme} >
        <AppBackDrop />
        <Router>
          <RouteSwitch>
            <Route exact path="/" render={() => this._getComponentToStart(null)} ></Route>
            <Route path="/:permalink/:id" render={routerProps => this._getComponentToStart(routerProps)} ></Route>
            <Route path="/:permalink" render={routerProps => this._getComponentToStart(routerProps)} ></Route>

          </RouteSwitch>
        </Router>

        <AppSnackBar />
        <AppDialog />
        <AppMenu />
      </ThemeProvider>
    );

  }
 
}

export default App; 